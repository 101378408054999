
const email = "orangebunchteam@garantexacademy.iam.gserviceaccount.com";
const private_key = "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDocZVt0pHoJ1Iv\nrSUKF9Xup/xel3Zq01VDgeSEGRoCkIWSvE2TQjA2me5GHTTa2VuoIvQmzKucMAkW\n/i6lBXh7nbODwud9leycJTpxIhF9vtux3F6ctU1ye3gwQGvWkrPneWqaxiqatJkM\nZ3K3pl8pyqlvalB+dNHhgQvqI/puO+cISSykjdjZfCwYu0KXvUay0MtQ8RqszRP6\nefnaKQOTdsxYhqJyn5QOaAJDdMsEhuyJEX+aMM0DQpsgMNq6jqwjoijHMAiBir9O\nVBPh20QtMRfzwbsBxioMaESR+HTL23YX5+YmUx+vUW9VKM4JX4SDtM3y5hIN2tqK\nSuUMNjXdAgMBAAECggEAVZVMkY0QT8v6hLdZhefUyvRrBWD7JsX9BMgUnVaorv0L\nUUWnwmqCWfg/NO2YAmQ5TSThhE+jd/RvxFl+TyA+hjVAXWkr1p+W6L8ET5ddnVDi\njxA2fbh7dYVkcU+m5PBK8RVRXP9NvHx2RYzpPhkxvK9zxyP70zOr+z2KZ4Erud38\nnyRtia/bLQQiRfkJJnvcgXVYxqZcGFpXmOhsi9Xm7PaB7D84W93qPQ49HkT+LBCR\nDAIYXbwZHh7msUkjiihcPVVRPobcoEuADTNhyb35z8ZKiOcBjY5D13z7yCK2rSBP\nJwgqh43yTQw5zqZL3jWS00a2mPXjOXpu4t6oGcEu+QKBgQD+P6vg12q4TwEP0IAa\nXsdO6c43LIWIlCKcVlkFzga3P/IoylXrNlYgEVehle1jIefznK3XaotN8BGRX6hj\nsHlx0OBOrUNV1hKDTeQKy7izpbiDT2JqAL7IoBebRW/wv1D78xi1ZFa7noGgytyu\nrf3frkkoNDulDyYf2PdOHhowMwKBgQDqC3ZlQx4x/pL3reVRGCAOAhFi+rFZoGqk\nlvOt1Ty6a1o2wrx0JxDMgFu+WgiJSwH3MtfuCfUplW4Nt8r51ko0uZCpXT/coKWd\n6s0GdvUDbfG2lDAECfa1k/E6jFpbcEykzL0uQcd+tJdLCl7aVADbFIH4dSOJ1mUH\ncnCmAxSxrwKBgQCM7u5vq/lUQTbtdT4NYAxLCf9tRhifJL4quxKNzS6b1a5+Pepy\nf6QuEm7o+xqjoN/agjc6+axOf4y9cLD+0YwAMzCjbBevNQL+FecatOKlcm9dGlwL\nNm6K93MedtceNG7HunEv23bYeXyRjOHXUksv+oRC7PFDBaq0bKhpfPY3UQKBgEot\nVdnkOta3i5bqS2q3TbmC2EXZlIoGeKYvnY2zrqqayZ3ffGEH3KAj8m/TEy+IUVgq\nihtMlqIWVSoN3UvvqdcqFPpIrqvbUoJJX6+z1hW/K8OlFc7B5apI+ESJJT4jynbU\n2QSnHnY/AFBtOgDSbO8CgergHWJbS+vfTmqfQVjLAoGAcLhS71wEhwBC97flacDt\nXugfVGwgcMpfG+tvxLAEg/u8oo+EgG1Rn38FVX4O+wusP61yH7cYtr4Yw+zDIDKZ\n9cgeDjMYNh1hs3nBNNHap0YTi67lOoeTRnN0cdpNU97MUBxGmr+UrPGbj9+gVpJ/\n5eaRbFGXq+bjJurRKDD5dIs=\n-----END PRIVATE KEY-----\n";
const SCOPES = ["https://www.googleapis.com/auth/spreadsheets"];

const DISCOVERY_DOCS = ["https://sheets.googleapis.com/$discovery/rest?version=v4"];

const spreadsheetId = '1Qjsv2YRwmQNXXa0hHJX3Dx33ZoOIKm3VK_cJHjszNXU';

// const start = () => {

//     // gapi.auth.authorize

//     gapi.client.init({
//         // apiKey: API_KEY,
//         clientId: CLIENT_ID,
//         discoveryDocs: DISCOVERY_DOCS,
//         scope: SCOPES,
//         immediate: true,
//     })
//         .then(async function () {

//             // gapi.client.setApiKey(API_KEY)
//             const auth2 = gapi.auth2.getAuthInstance();

//             if (auth2.isSignedIn.get()) {
//                 console.log('lllll')
//                 callback();
//             } else {


// //                 auth2.grantOfflineAccess()
// //    .then((res) => {
// //        console.log('aaaaa')
// //       console.log(res);
// //       this.data.refreshToken = res.code;
// //    });
//                 // console.log(gapi.client.getToken())
//                 // if(gapi.client.getToken() ===  null){
//                 //     console.log('asas')
//                 //     gapi.auth.authorize({

//                 //         client_id: CLIENT_ID,
//                 //         // discoveryDocs: DISCOVERY_DOCS,
//                 //         scope: SCOPES,
//                 //         immediate: true, 
//                 //     })
//                 // }
//                 // console.log(gapi.auth.getToken())
//                 // gapi.client.setToken({ access_token: toketn })
//             }
//             // updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
//             // authorizeButton.style.display = 'block';
//             // authorizeButton.onclick = execute;
//             console.log(gapi.auth2.getAuthInstance().isSignedIn.get()) //false
//         });

// }

const getFormattedDate = () => {
    var d = new Date();

    d = d.getFullYear() + "-" + ('0' + (d.getMonth() + 1)).slice(-2) + "-" + ('0' + d.getDate()).slice(-2) + " " + ('0' + d.getHours()).slice(-2) + ":" + ('0' + d.getMinutes()).slice(-2) + ":" + ('0' + d.getSeconds()).slice(-2);

    return d;
}
// Make sure the client is loaded before calling this method.
const execute = (email = null, name = null, topic = null, phone = null) => {
    const dateTime = getFormattedDate();

    const params = {
        spreadsheetId: spreadsheetId,
        range: 'list1!A:E',
        valueInputOption: 'RAW',
        rows: [{
            "values": [
                { "userEnteredValue": { "stringValue": dateTime } },
                { "userEnteredValue": { "stringValue": topic } },
                { "userEnteredValue": { "stringValue": phone } },
                { "userEnteredValue": { "stringValue": email } }
            ]
        }],
    };

    const valueRangeBody = {
        "range": "list1!A:E",
        "values": [
            [dateTime, topic, phone, email]
        ],
    };

    const request = gapi.client.sheets.spreadsheets.values.append(params, valueRangeBody);
    request.then((response) => {
        formSuccess.classList.toggle('active');
        newTheme.reset();
        console.log(response.result);
    }, function (reason) {
        console.error('error: ' + reason.result.error.message);
    });

}

// const formHowWorkForm = document.getElementById('howWorkForm');

// formHowWorkForm.addEventListener('submit', (event) => {
//     // stop form submission
//     event.preventDefault();
//     const email = formHowWorkForm.elements[0].value;
//     if (email.length === 0) {
//         return;
//     }
//     execute(email);
// });

// const lastForm = document.getElementById('lastForm');

// lastForm.addEventListener('submit', (event) => {
//     // stop form submission
//     event.preventDefault();
//     const email = lastForm.elements[0].value;
//     if (email.length === 0) {
//         return;
//     }
//     execute(email);
// });

const newTheme = document.getElementById('newTheme');
const formSuccess = document.getElementById("formSuccess");

newTheme.addEventListener('change', () => {
    if(formSuccess.classList.contains('active')){
        formSuccess.classList.toggle('active');
    }  
});

newTheme.addEventListener('submit', (event) => {
    // stop form submission
    event.preventDefault();
    const topic = newTheme.elements[0].value;
    const email = newTheme.elements[1].value;
    const phone = newTheme.elements[2].value;
    if (email.length === 0) {
        return;
    }
    execute(email, null, topic, phone);
});

const object = {
    private_key: private_key,
    client_email: email,
    scopes: SCOPES,
};

const handleClientLoad = () =>
    gapi.load("client", async () => {
        gapi.auth.setToken(await GetAccessTokenFromServiceAccount.do(object))

        gapi.client
            .init({
                discoveryDocs: DISCOVERY_DOCS,
            }).then(() => {
                console.log('aaaaaa')
            })
    }
    );
